import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Pagination, PaginationItem, PaginationLink  } from 'reactstrap';
import CustomCheckbox from './CustomCheckbox'; // Import the custom checkbox
import './CustomPagination.css'; // Import the custom CSS file
import YearSelector from './YearSelector';
import './SearchWithYearSelector.css';
import PaginationWithRowsSelector from './PaginationWithRowsSelector';
import team4 from "assets/images/pdf.jpg";
import MDAvatar from "components/MDAvatar";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const PaginatedTable = ({ data, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage)

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleYearChange = (year) => setSelectedYear(year);

  const [sortField, setSortField] = useState("")
  const [sortArrow, setSortArrow] = useState(0)

  // Example years; adjust as needed
  let years = []
  for(let i = 2030; i >= 2018; i--)
    years.push(i)
  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    let filtered = data.filter(item =>
      Object.keys(item).some(key =>
        String(item[key]).toLowerCase().includes(lowercasedFilter)
      )
    ).filter(item => String(item['created_at']).includes(String(selectedYear))
    );

    if(sortField != "") {
      if(sortField == 'no') {
        if(sortArrow == 1) 
          filtered = filtered.reverse()
      }else{
        if(sortArrow == 0)
          filtered = filtered.sort((a, b) => String(a[sortField]).localeCompare(b[sortField]));
        else
          filtered = filtered.sort((a, b) => String(b[sortField]).localeCompare(a[sortField]));
      }
      
    }
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page after search
  }, [searchTerm, data, selectedYear, sortField, sortArrow]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleRows = (count) => {
    setRowsPerPage(count)
  }

  const pagesCount = Math.ceil(filteredData.length / rowsPerPage);
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = filteredData.slice(start, end);

  const statusColorsArray = {
    'Accept': 'rgb(4 133 4)',
    'Reject' : 'rgb(185 7 7)',
    'Pending' : 'rgb(151 151 49)'
}

  return (
    <div>
       <div className="search-container" style={{
        justifyContent: "space-between"
       }}>
        <div>
          <YearSelector
            years={years}
            selectedYear={selectedYear}
            onChange={handleYearChange}
          />
        </div>
         <div >
         <Input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          style={{ 
            width: '300px',
            borderRadius: '3px',
            color: '#abaaaa',
            border: '1px solid #d3d1d1'
          }}
        />
         </div>
      </div>
      
      <Table>
        <thead style={{ backgroundColor: '#f8f9fa' }}>
          <tr>
            <th 
              onClick={() => {
              }}
              style={{ color: '#51bcda', fontWeight: 'bold', cursor: 'pointer' , gap: '5px', alignItems: 'center'}}
            >
              <b>No</b>
              { sortField == "no" && 
                <> 
                  { sortArrow == 0 && <ArrowDownwardIcon />}
                  { sortArrow == 1 && <ArrowUpwardIcon />}
                </>
              }
              
            </th>
            <th 
              onClick={() => {
                if(sortField != 'invoice_number'){
                  setSortField('invoice_number')
                  setSortArrow(0)
                }else{
                  setSortArrow((sortArrow) => 1 - sortArrow)
                }
              }}
              style={{ color: '#51bcda', fontWeight: 'bold', cursor: 'pointer' , gap: '5px', alignItems: 'center'}}
            >
              <b>Invoice#</b>
              { sortField == "invoice_number" && 
                <> 
                  { sortArrow == 0 && <ArrowDownwardIcon />}
                  { sortArrow == 1 && <ArrowUpwardIcon />}
                </>
              }
            </th>
            <th 
              onClick={() => {
                if(sortField != 'from'){
                  setSortField('from')
                  setSortArrow(0)
                }else{
                  setSortArrow((sortArrow) => 1 - sortArrow)
                }
              }}
              style={{ color: '#51bcda', fontWeight: 'bold', cursor: 'pointer' , gap: '5px', alignItems: 'center'}}
            >
              <b>From</b>
              { sortField == "from" && 
                <> 
                  { sortArrow == 0 && <ArrowDownwardIcon />}
                  { sortArrow == 1 && <ArrowUpwardIcon />}
                </>
              }
            </th>
            <th 
              onClick={() => {
                if(sortField != 'created_at'){
                  setSortField('created_at')
                  setSortArrow(0)
                }else{
                  setSortArrow((sortArrow) => 1 - sortArrow)
                }
              }}
              style={{ color: '#51bcda', fontWeight: 'bold', cursor: 'pointer' , gap: '5px', alignItems: 'center'}}
            >
              <b>Created</b>
              { sortField == "created_at" && 
                <> 
                  { sortArrow == 0 && <ArrowDownwardIcon />}
                  { sortArrow == 1 && <ArrowUpwardIcon />}
                </>
              }
            </th>
           
            <th 
              onClick={() => {
                if(sortField != 'to'){
                  setSortField('to')
                  setSortArrow(0)
                }else{
                  setSortArrow((sortArrow) => 1 - sortArrow)
                }
              }}
              style={{ color: '#51bcda', fontWeight: 'bold', cursor: 'pointer' , gap: '5px', alignItems: 'center'}}
            >
              <b>To</b>
              { sortField == "to" && 
                <> 
                  { sortArrow == 0 && <ArrowDownwardIcon />}
                  { sortArrow == 1 && <ArrowUpwardIcon />}
                </>
              }
            </th>
            <th 
              onClick={() => {
                if(sortField != 'updated_at'){
                  setSortField('updated_at')
                  setSortArrow(0)
                }else{
                  setSortArrow((sortArrow) => 1 - sortArrow)
                }
              }}
              style={{ color: '#51bcda', fontWeight: 'bold', cursor: 'pointer' , gap: '5px', alignItems: 'center'}}
            >
              <b>Updated</b>
              { sortField == "updated_at" && 
                <> 
                  { sortArrow == 0 && <ArrowDownwardIcon />}
                  { sortArrow == 1 && <ArrowUpwardIcon />}
                </>
              }
            </th>
            <th 
              onClick={() => {
                if(sortField != 'status'){
                  setSortField('status')
                  setSortArrow(0)
                }else{
                  setSortArrow((sortArrow) => 1 - sortArrow)
                }
              }}
              style={{ color: '#51bcda', fontWeight: 'bold', cursor: 'pointer' , gap: '5px', alignItems: 'center'}}
            >
              <b>Status</b>
              { sortField == "status" && 
                <> 
                  { sortArrow == 0 && <ArrowDownwardIcon />}
                  { sortArrow == 1 && <ArrowUpwardIcon />}
                </>
              }
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr key={index} style={{fontSize: "14px"}}>
              <td>{index+1}</td>
              <td><b style={{
                display: "flex",
                alignItems: "center"
              }}>
                {item.invoice_number}
              </b></td>
              <td>{item.from}</td>
              <td>{item.created_at}</td>
              
              <td>{item.to}</td>
              <td>{item.updated_at}</td>
              <td>
                <div style={{
                  margin: '3px',
                  width: '65px',
                  padding: '3px',
                  borderRadius: '25px',
                  color: 'white',
                  textAlign: 'center',
                  backgroundColor: statusColorsArray[item.status],
                  fontWeight: 'bold'
                }}>
                  {
                  item.status}
                </div>
                </td>
              
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationWithRowsSelector
        itemsPerPage={10}
        totalItems={filteredData.length}
        paginate={handlePagination}
        changeRows = {handleRows}
      />
        
    </div>
  );
};

PaginatedTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    invoice_number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  })).isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default PaginatedTable;
