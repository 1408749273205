// src/reducers/tokens-reducer.js

import {
  GET_TOKEN,
  GET_TOKEN_REQUESTED,
  GET_TOKEN_COMPLETED,
  GET_TOKEN_FAILED,
} from '../actions/tokens'

const initialState = {
  isRequesting: false,
  isRequestingFailed: false,
  data: undefined,
};

export default function tokensReducer(state = initialState, action = {}) {

  switch(action.type) {
  case GET_TOKEN_REQUESTED:
    return Object.assign({}, state, {
      isRequesting: true,
      isRequestingFailed: false,
    })

  case GET_TOKEN_FAILED:
    return Object.assign({}, state, {
      isRequesting: false,
      isRequestingFailed: true,
    })

  case GET_TOKEN_COMPLETED:
    return Object.assign({}, state, {
      isRequesting: false,
      isRequestingFailed: false,
      data: action.results,
    })

  default:
    return state
  }
}
