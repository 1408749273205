import React from "react";
import PropTypes from "prop-types";
import "../../assets/css/landing.css";
import "../../assets/css/bootstrap/bootstrap.css";
import "../../assets/css/bootstrap/bootstrap-grid.css";
import "../../assets/css/bootstrap/bootstrap-reboot.css";

// core components
import Header from "../../components/header/header.js";
import HeaderBanner from "../../components/banner/banner.js";
import Footer from "../../components/footer/footer.js";
import DashboardUseInfo from "../../components/info/useinfo.js";
import AboutUsSection from "components/AboutUsSection";
import ContactUs from "components/ContactUS";

import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Components = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
  })

  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <HeaderBanner />
          {/* <DashboardUseInfo /> */}
          <AboutUsSection />
          <ContactUs />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Components.propTypes = {
  classes: PropTypes.object,
};

export default Components;
