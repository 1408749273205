// src/layouts/InvoiceHistory/PaginationWithRowsSelector.js
import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import RowsPerPageSelector from './RowsPerPageSelector';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './PaginationWithRowsSelector.css'; // Import CSS for styling

const PaginationWithRowsSelector = ({ itemsPerPage, totalItems, paginate, changeRows }) => {
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(Number(newRowsPerPage));
    setCurrentPage(1); // Reset to the first page
    paginate(1);
    changeRows(newRowsPerPage);
  };

  return (
    <div className="pagination-container">
      <RowsPerPageSelector
        rowsPerPage={rowsPerPage}
        onChange={handleRowsPerPageChange}
      />
      <Pagination className="pagination">
        {pageNumbers.map((number) => (
          <PaginationItem key={number} active={number === currentPage}>
            <PaginationLink
              href="#!"
              onClick={() => {
                setCurrentPage(number);
                paginate(number);
              }}
            >
              {number}
            </PaginationLink>
          </PaginationItem>
        ))}
      </Pagination>
    </div>
  );
};

// Define PropTypes for the PaginationWithRowsSelector component
PaginationWithRowsSelector.propTypes = {
  itemsPerPage: PropTypes.number.isRequired, // Number of items per page
  totalItems: PropTypes.number.isRequired, // Total number of items
  paginate: PropTypes.func.isRequired, // Pagination handler function,
  changeRows: PropTypes.func.isRequired, // Pagination handler function,
};

export default PaginationWithRowsSelector;
