/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Chartlayout from "./layouts/chartlayout";
import Users from "./layouts/users";
import Invoices from './layouts/invoices';
import Company from './layouts/company';
import Landing from "./layouts/landingpage";
import Setup from "./components/setup";
import Support from "./layouts/support";
import Policy from "./components/Policy";
import CheckoutSuccess from "layouts/pricingpage/success";
import CheckoutFail from "layouts/pricingpage/canceled";

// @mui icons
import Icon from "@mui/material/Icon";
import PricingPage from "./layouts/pricingpage";
import InvoiceHistory from "layouts/InvoiceHistory";

const routes = [
  {
    type: "title",
    name: "Landing",
    key: "landing",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/",
    component: <Landing />,
  },
  
  {
    type: "title",
    name: "SuccessPayment",
    key: "success",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/success",
    component: <CheckoutSuccess />,
  },
  {
    type: "title",
    name: "CanceledPayment",
    key: "canceled",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/canceled",
    component: <CheckoutFail />,
  },
  {
    type: "title",
    name: "Setup",
    key: "setup",
    route: "/setup",
    component: <Setup />,
  },
  {
    path: '/company',
    type: "collapse",
    name: "Company",
    key: "company",
    icon: "nc-icon nc-globe",
    route: "/manage/company",
    component: <Company />,
    layout: '/manage'
  },
  {
    path: '/chart',
    type: "collapse",
    name: "Chart",
    key: "dashboard",
    icon: "nc-icon nc-chart-bar-32",
    route: "/manage/chart",
    component: <Chartlayout />,
    layout: '/manage'
  },
  {
    path: '/users',
    type: "collapse",
    name: "User",
    key: "users",
    icon: "nc-icon nc-single-02",
    route: "/manage/users",
    component: <Users />,
    layout: '/manage'
  },
  {
    path: '/invoice_history',
    type: "collapse",
    name: "Invoice",
    key: "invoice_history",
    icon: "nc-icon nc-credit-card",
    route: "/manage/invoice_history",
    component: <InvoiceHistory />,
    layout: '/manage'
  },
  {
    path: '/pricing',
    type: "title",
    name: "Price",
    key: "pricingpage",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/manage/pricing",
    component: <PricingPage />,
    layout: '/manage'
  },
  {
    path: '/support',
    type: "collapse",
    name: "support",
    key: "support",
    icon: "nc-icon nc-credit-card",
    route: "/support",
    component: <Support />,
    layout: '/support'
  },
  {
    path: '/policy',
    type: "collapse",
    name: "policy",
    key: "policy",
    icon: "nc-icon nc-credit-card",
    route: "/policy",
    component: <Policy />,
    layout: '/policy'
  },
];

export default routes;
