import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

// Redux store import
import configureStore from "./store"; // Assuming you have a store.js file
import { Provider as ReduxProvider } from 'react-redux';

const store = configureStore();

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </ReduxProvider>
);
