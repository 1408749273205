import PaginatedTable from './paginatedTable';

import React, { useEffect, useState } from "react";
import * as api from 'actions/api_auth_client'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText 
} from "reactstrap";

import AdminLayout from 'layouts/Admin'

function InvoiceHistory() {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('user_id');
    const [data, setData] = useState([])

    const columns = [
        { 
            field: 'id', 
            headerName: 'No', 
            width: 90,
        },
        {
          field: 'invoice_number',
          headerName: 'Invoice#',
        },
        {
          field: 'created_at',
          headerName: 'Created',
        },
        {
          field: 'from',
          headerName: 'From'
        },
        {
            field: 'to',
            headerName: 'To'
        },
        {
            field: 'status',
            headerName: 'status'
        },
        {
            field: 'updated_at',
            headerName: 'Updated'
        }
      ];

    const temp = [
        {
            "id": 1,
            "invoice_number": null,
            "status": "Reject",
            "created_at": "2024-06-30T14:35:54.541Z",
            "updated_at": "2024-06-30T14:35:54.541Z",
            "from": "Qin Cheng",
            "to": "WenYi"
        },
        {
            "id": 1,
            "invoice_number": "wenyi_1.pdf",
            "status": "Accept",
            "created_at": "2024-07-29T14:57:04.821Z",
            "updated_at": "2024-07-29T14:57:04.821Z",
            "from": "WenYi",
            "to": "WenYi"
        },
        {
            "id": 1,
            "invoice_number": "wenyi_2.pdf",
            "status": "Accept",
            "created_at": "2024-07-31T07:35:15.227Z",
            "updated_at": "2024-07-31T07:35:15.227Z",
            "from": "WenYi",
            "to": "ZhiYuan"
        },
        {
            "id": 1,
            "invoice_number": "wenyi_123213.pdf",
            "status": "Pending",
            "created_at": "2024-06-10T16:35:06.421Z",
            "updated_at": "2024-06-10T16:35:06.421Z",
            "from": "WenYi",
            "to": "Qin Cheng"
        },
        {
            "id": 1,
            "invoice_number": "wenyi_123.pdf",
            "status": "Reject",
            "created_at": "2024-06-30T14:34:27.905Z",
            "updated_at": "2024-06-30T14:34:27.905Z",
            "from": "WenYi",
            "to": "Qin Cheng"
        }
        // Add more data as needed
      ];
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        try {
          const team_id = localStorage.getItem('team_id')
          api.getApi(`/get_invoices?team_id=${team_id}&user_id=${userId}`).
          then((results) => {
            const invoiceData = results.invoiceData;
            setData(invoiceData)
          }
          ).catch((error)=> {

          })
    
        }catch (error) {
          
        }
      }
    
    
    
    return (
        <AdminLayout>
            <div className="content" style={{marginTop: "25px", padding: "0px 80px"}}>
                <Row>
                    <Col md="12">
                        <Card>
                        <CardBody>
                            <PaginatedTable data={data} itemsPerPage={10} />
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
           
        </AdminLayout>
    );
}

export default InvoiceHistory;
