import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import birdLogo from '../../assets/images/logos/bird-logo.png';
import PropTypes from 'prop-types';

import routes from "routes"
import invoiceImg from '../../assets/images/invoice.png'
import companyImg from '../../assets/images/company.png'
import chartImg from '../../assets/images/chart.png'
import userImg from '../../assets/images/user.png'
import priceImg from '../../assets/images/Price.png'
import config from '../../config'
var ps;
const baseUrl = config.baseUrl;
function Sidebar(props) {
  const location = useLocation();
  const sidebar = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const token = localStorage.getItem('token');
  const subscription_flag = localStorage.getItem('subscription_flag');
  const team_id = localStorage.getItem('team_id');
  let s_flag;
  if(subscription_flag === 'true'){
    s_flag = true;
  }else{
    s_flag = false;
  }

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a
          href="/"
          className="simple-text logo-mini"
        >
          <div className="logo-img">
            <img src={birdLogo} alt="react-logo" />
          </div>
        </a>
        <a
          href="/"
          className="simple-text logo-normal"
          style={{textTransform: 'none'}}
        >
          FSDbird
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {routes.map((prop, key) => {
            return (
              prop.route.includes('/manage') && <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                {
                  prop.name != "Price" &&
                  <NavLink to={prop.layout + prop.path} className="nav-NavLink" style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}>
                    {prop.name == 'Invoice' &&
                      <img src={invoiceImg} alt="react-logo" style={{
                        width: '32px',
                        height: '30px',
                        marginRight: '12px'
                      }}/>
                    }
                    {prop.name == 'Chart' &&
                      <img src={chartImg} alt="react-logo" style={{
                        width: '32px',
                        height: '30px',
                        marginRight: '12px'
                      }}/>
                    }
                    {prop.name == 'Company' &&
                      <img src={companyImg} alt="react-logo" style={{
                        width: '32px',
                        height: '30px',
                        marginRight: '12px'
                      }}/>
                    }
                    {prop.name == 'User' &&
                      <img src={userImg} alt="react-logo" style={{
                        width: '30px',
                        height: '28px',
                        marginRight: '12px'
                      }}/>
                    }
                    {prop.name != 'Invoice' && prop.name != 'Company' && prop.name != 'Chart' && prop.name != 'User'  &&
                      <i className={prop.icon} />
                    }
                    
                    <p>{prop.name}</p>
                  </NavLink>
                }
                {
                  prop.name == "Price" && token && !s_flag &&
                  <NavLink to={prop.layout + prop.path} className="nav-NavLink" style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}>
                    
                      <img src={priceImg} alt="react-logo" style={{
                        width: '32px',
                        height: '30px',
                        marginRight: '12px'
                      }}/>
                    

                    <p>{prop.name}</p>
                  </NavLink>
                }
                {
                  prop.name == "Price" && token && s_flag &&
                  <div  to={`${baseUrl}/billing-portal`} className="nav-NavLink" style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '10px 22px'
                  }}>
                    
                      <img src={priceImg} alt="react-logo" style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '12px'
                      }}/>
                    
                    <form action={`${baseUrl}/billing-portal`} method="POST">
                          <input type="hidden" name="team_id" value={team_id}/>
                          <button type="submit" style={{
                            background: 'none',
                            border: 'none',
                            padding: '0',
                            margin: '0',
                            fontSize: '12px',
                            color: '#B4A9A9',  // Make it look like a link
                            cursor: 'pointer'
                          }}>
                            PRICE
                          </button>
                    </form>
                  </div >
                }
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  bgColor: PropTypes.string,
  activeColor: PropTypes.string,
};

export default Sidebar;
