import assign from 'lodash/assign'
import reduce from 'lodash/reduce'
import * as tokens from './tokens';
import * as charts from './charts';
import * as prices from './prices';

const actions = 
  reduce([
    tokens,
    charts,
    prices,
  ], (result, value)=> {
    return assign(result, value)
  }, {})

export default actions
