import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './css/normalize.css'; 
import config from '../../config';
import logo from '../../assets/images/logos/white-text.png';
import Box from '@mui/material/Box';
import { Container, NavbarBrand } from 'reactstrap';
import Confetti from 'react-confetti'; // Fireworks effect

const baseUrl = config.baseUrl;

const CheckoutSuccess = () => {
  const [sessionJSON, setSessionJSON] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await fetch(`${baseUrl}/checkout-session?sessionId=${sessionId}`);
          const session = await response.json();
          setSessionJSON(JSON.stringify(session, null, 2));
          localStorage.setItem('subscription_flag', "true");
        } catch (error) {
          console.error('Error fetching session data:', error);
        }
      }
    };

    fetchSessionData();
  }, [sessionId]);

  useEffect(() => {
    // Redirect to "/" after 2 seconds
    const timer = setTimeout(() => {
      window.location.href = '/';
    }, 3000);

    // Cleanup the timer if the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="main-wrapper" className="common-back">
      {/* Fireworks Effect */}
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={400}
      />
      <div className="topbar" id="top">
        <div 
          style={{
            fontFamily: 'Lato, sans-serif',
            position: 'fixed',
            top: 10,
            left: 0,
            right: 0,
            zIndex: 100
          }}
        >
          <Container className="po-relative" style={{maxWidth: '95%'}}>
            <div className="navbar-expand-lg h6-nav-bar d-flex" style={{display: 'flex', justifyContent: 'space-between', maxHeight: '80px'}}>
              <NavbarBrand href="/" style={{
                padding: '0px'
              }}>
                <Box
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                  }}
                >
                  <img 
                    src={logo} 
                    style={{
                      width: '60px'
                    }}
                    alt="wrapkit" />
                  <span 
                    className="nav-link"
                    style={{
                      padding: '0px',
                      color: 'white'
                    }}
                  >FSDbird</span>
                </Box>
              </NavbarBrand>
            </div>
          </Container>
        </div>
      </div>
      <div className="paymentpage-wrapper">
        <div className="container-fluid">
          <div className="mt-15 sr-payment-summary completed-view">
            <h1>Your payment succeeded</h1>
            <p>Thank you for your purchase! Your subscription is now active.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
