import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import UsersDataTable  from "layouts/users/data/authorsTableData";
import { useAuth } from '../../components/useAuth';

import AdminLayout from 'layouts/Admin'

function Users() {
  // Check authorization
  const isAuthenticated = useAuth();
  if (!isAuthenticated) {
    return null;
  }

  return (
    <AdminLayout>
      <MDBox pt={6} pb={3} style={{marginTop: "25px", padding: "0px 80px"}}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <UsersDataTable />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </AdminLayout>
  );
}

export default Users;
