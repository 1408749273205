// src/actions/api_client.js

import axios from 'axios';
import config from '../config'

const apiAuthClient = axios.create({
  baseURL: config.baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
});

export const getApi = async (endpoint) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiAuthClient.get(endpoint, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postApi = async (endpoint, data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiAuthClient.post(endpoint, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putApi = async (endpoint, data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiAuthClient.put(endpoint, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteApi = async (endpoint) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiAuthClient.delete(endpoint, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};