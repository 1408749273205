// src/components/RowsPerPageSelector.js
import React from 'react';
import PropTypes from 'prop-types';

const RowsPerPageSelector = ({ rowsPerPage, onChange }) => {
  return (
    <div>
      <div style={{
        display:"flex",
        position:"relative",
        alignItems: "center"
      }}>
        <div>
        
          <select
            value={rowsPerPage}
            onChange={(e) => onChange(e.target.value)}
            className="rows-per-page-selector"
            style={{
              fontSize: "18px",
              height: "35px",
              borderRadius: "3px",
              borderColor: "#dbd7d7",
              backgroundColor: "transparent",
              color:"#8b8b8b",
              textAlign: "center",
              position: "relative"

            }}  
          >
            {[10, 15, 25, 50].map((num) => (
              <option key={num} value={num}>
                {num} 
              </option>
            ))}
          </select></div> 
          <div style={{fontSize: "15px", marginLeft: "5px"}}>Rows Per Page</div>
      </div>
    </div>
  );
};

RowsPerPageSelector.propTypes = {
  rowsPerPage: PropTypes.number.isRequired, // Current rows per page
  onChange: PropTypes.func.isRequired, // onChange handler
};

export default RowsPerPageSelector;
