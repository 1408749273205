// src/actions/api_client.js

import axios from 'axios';
import config from '../config'

const token = localStorage.getItem('token');

const apiFileClient = axios.create({
  baseURL: config.baseUrl,
  headers: {
    'Authorization': `Bearer ${token}`,
  },
});

export const getApi = async (endpoint) => {
  try {
    const response = await apiFileClient.get(endpoint,  { responseType: 'blob' });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postApi = async (endpoint, data) => {
  try {
    const response = await apiFileClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putApi = async (endpoint, data) => {
  try {
    const response = await apiFileClient.put(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteApi = async (endpoint) => {
  try {
    const response = await apiFileClient.delete(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};