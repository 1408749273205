// src/actions/prices.js

import * as api from './api_auth_client'

export const GET_PRICES = "GET_PRICES"
export const GET_PRICES_REQUESTED = "GET_PRICES_REQUESTED"
export const GET_PRICES_COMPLETED = "GET_PRICES_COMPLETED"
export const GET_PRICES_FAILED = "GET_PRICES_FAILED"

export function getPricesRequested() {
  return {
    type: GET_PRICES_REQUESTED,
  }
}

export function getPrices(selectedDate) {
  return function(dispatch) {
    dispatch(getPricesRequested());
    return api.getApi(`/config`)
      .then((results)=> {
        dispatch(getPricesCompleted(results));
      })
      .catch((error)=> {
        Promise.all([
          dispatch(getPricesFailed(error)),
        ])
      })
  }
}

export function getPricesCompleted(results) {
  return {
    type: GET_PRICES_COMPLETED,
    results
  }
}

export function getPricesFailed(error) {
  return {
    type: GET_PRICES_FAILED,
    error
  }
}
