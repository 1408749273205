/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import actions from '../actions';
import { useNavigate } from 'react-router-dom';

const Setup = ({ data, isRequesting, isRequestingFailed }) =>{
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(data) {
      const { token, team_name, team_id, ws_id, subscription_flag, is_admin } = data;

      // Check if user is admin or not
      if(is_admin) {
        // Store the token in localStorage or in-memory storage as per your security policy
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('token', token);
        localStorage.setItem('team_name', team_name);
        localStorage.setItem('team_id', team_id);
        localStorage.setItem('ws_id', ws_id);
        localStorage.setItem('subscription_flag', subscription_flag);
        // Also store the current timestamp
        const expiryTime = new Date().getTime() + (60 * 60 * 1000); // Current time plus 1 hour (in milliseconds)
        localStorage.setItem('expiryTime', expiryTime.toString());
  
        navigate('/'); 
      } else {
        let timeoutId;
        setErrorMessage('You are not owner of current workspace. You have no permission to signin.');
        timeoutId = setTimeout(() => {
          navigate('/landing');
        }, 3000);
        // Cleanup function to clear the timeout
        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }
    }
  }, [data, navigate]);

  useEffect(() => {
    let timeoutId;
    if (isRequestingFailed) {
      setErrorMessage('An error occurred while processing your request.');
      timeoutId = setTimeout(() => {
        navigate('/landing');
      }, 3000);
    }
    // Cleanup function to clear the timeout
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isRequestingFailed, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    if (code) {
      dispatch(actions.getToken(code));
    }
  }, [dispatch]);
  
  if (errorMessage) {
    return (
      <div>
        <div className="error-container">
          <h3>Error</h3>
          <p style={{ color: 'red' }}>{errorMessage}</p>
          <p>You will be redirected to langing page shortly...</p>
        </div>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  data: state.tokens.data,
  isRequesting: state.tokens.isRequesting,
  isRequestingFailed: state.tokens.isRequestingFailed
});

Setup.propTypes = {
  data: PropTypes.object,
  isRequesting: PropTypes.bool,
  isRequestingFailed: PropTypes.bool
};

export default connect(mapStateToProps)(Setup);
