/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import * as api from 'actions/api_auth_client'
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import users_icon from "assets/images/users.png";
import React, { useState, useEffect } from 'react';
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import PromptDialog from './PromptDialog'; // Adjust the path as needed
import NotificationAlert from "react-notification-alert";
import config from '../../../config';
import { DataArraySharp } from '@mui/icons-material';
import boxShadow from 'assets/theme/functions/boxShadow';
import './table.css'

export default function UsersDataTable() {
  const notificationAlert = React.useRef();
  const [github, setGithub] = useState('')
  const [gitlab, setGitlab] = useState('')
  const [userId, setUserId] = useState(0)
  const notify = (place, msg, color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {msg}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [open, setOpen] = useState(false);

  const handleClickOpen = (user) => {
      setGithub(user.github_username)
      setGitlab(user.labhub_username)
      setUserId(parseInt(user.id))
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };
  const updateGitInfo = async (github_username, gitlab_username, user) => {
    const data = {
      user_id: user,
      github_username: github_username,
      labhub_username: gitlab_username
    };
  
    try {
      const response = await fetch(`${config.baseUrl}/update_gitinfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      if (response.ok) {
        const result = await response.json();
        notify("tr", "Updated successfully.", 5)
        location.reload()
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleConfirm = (github, gitlab, user) => {
      if(github == "" || gitlab == "") {
        notify("tr", "Please put git name", 3)
      }else{
        updateGitInfo(github, gitlab, user)
      }
  };


  const Author = ({ image, name, slackId }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  // Use the useEffect hook to fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const team_id = localStorage.getItem('team_id')
        api.getApi(`/get_users?team_id=${team_id}`).
        then((results) => {
          
          const usersData = results.users_data;
  
          const updatedRowData = usersData.map((user, index) => ({
              no: <MDBox >{index + 1}</MDBox>,
              author: <Author image={users_icon} name={user.real_name} slackId={user.user_id} />,
              function: <Job title={user.title} description={user.is_admin?'admin': ''} />,
              git_name: <MDBox style={{ display: 'flex', gap: '15px', alignItems: 'center', width: '100%'}}><MDBox ml={-1} style={{color: '#0a7fb9', fontWeight: 'bold', width: '50%'}}>{user.github_username} </MDBox><MDBox style={{color: '#74741a', fontWeight: 'bold', width: '50%'}} ml={-1}>{user.labhub_username}</MDBox></MDBox>,
              // lab_name: <MDBox ml={-1}>{user.labhub_username}</MDBox>,
              timezone: <MDBox ml={-1}>{user.tz}</MDBox>,
              status: (
                <MDBox ml={-1}>
                  <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
                </MDBox>
              ),
              employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                  {user.created_at}
                </MDTypography>
              ),
              invoice: (
              
                <MDTypography variant="caption" color="text" fontWeight="medium" onClick={() => handleClickOpen(user)}>
                  <MDButton color="info" size="small">
                    Change Git
                  </MDButton>
                </MDTypography>
              
              ),
          }));
          setData(updatedRowData)
          setLoading(false);
        }
        ).catch((error)=> {
          console.error("error trying the request.")
          setLoading(false);
        })

      }catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }

    fetchData();
  }, []); 

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!rows || rows.length === 0) {
    return <p > No data available. It seems the request has been failed.</p>;
  }

  const columns = [
    { Header: "No", accessor: "no", width: '30px', align: "center" },
    { Header: "user", accessor: "author", width: "20%", align: "left" },
    { Header: "role", accessor: "function", align: "left" },
   
    { Header: "timezone", accessor: "timezone", align: "center" },
    { Header: "Date created", accessor: "employed", align: "center" },
    { Header: "git name", accessor: "git_name", align: "center" },
    // { Header: "gitlab name", accessor: "lab_name", align: "center" },
    { Header: "Action", accessor: "invoice", align: "center" },
  ]

  
  return (
    <div className="userTable">
      <NotificationAlert ref={notificationAlert} />
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
        sx={{
          boxShadow: "none"
        }}
    />
      <PromptDialog
            open={open}
            onClose={handleClose}
            onConfirm={handleConfirm}
            github={github}
            gitlab={gitlab}
            userId={userId}
        />
    </div>
    
  );
}
