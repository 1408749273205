// src/components/CustomCheckbox.js
import React from 'react';
import './CustomCheckbox.css'; // Import the CSS file
import PropTypes from 'prop-types';

const CustomCheckbox = ({ checked, onChange }) => {
  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="checkbox-input"
      />
      <span className="checkbox-custom"></span>
    </label>
  );
};

CustomCheckbox.propTypes = {
    checked: PropTypes.bool.isRequired,  // Specifies that 'checked' is a required boolean
    onChange: PropTypes.func.isRequired  // Specifies that 'onChange' is a required function
};

export default CustomCheckbox;
