import React, {useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";

import { useNavigate } from 'react-router-dom';
import routes from "routes.js";
import LogoutIcon from '@mui/icons-material/Logout';
import * as api from '../../actions/api_auth_client'
import invoiceImg from '../../assets/images/invoice.png'
import companyImg from '../../assets/images/company.png'
import chartImg from '../../assets/images/chart.png'
import userImg from '../../assets/images/user.png'

function Header() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();

  const [teamName, setTeamName] = React.useState(null);
  const navigate = useNavigate();

  const [logoImg, setLogoImg] = useState("")

  useEffect(() => {
    let team_id = localStorage.getItem('team_id')
    
    if(localStorage.getItem('logoImg') != undefined && localStorage.getItem('logoImg') != "") {
      setLogoImg( localStorage.getItem('logoImg'))
    }else {
      const apiUrl = `/get-company/${team_id}`;
      api.getApi(apiUrl)
          .then((response) => {
            localStorage.setItem('logoImg',`data:image/png;base64,${response['company']['logoFileBase64']}` )
            setLogoImg( `data:image/png;base64,${response['company']['logoFileBase64']}`)
          })
          .catch(error => {
            console.error(error)
          });
    }
  }, [])

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const getBrand = () => {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    const team_name = localStorage.getItem('team_name');
    if (token) {
      setTeamName(team_name);
    }
  }, [])

  const handleSignout = () => {
    localStorage.setItem('isLoggedIn', false);
    localStorage.removeItem('token');
    localStorage.removeItem('team_name');
    localStorage.removeItem('team_id');
    localStorage.removeItem('ws_id');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('subscription_flag');
    navigate('/');
  };

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
        <div className="navbar-wrapper" style={{marginLeft: '20px'}}>
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="/">
          {getBrand()=='Invoice' && <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
            <img src={invoiceImg} alt="react-logo" style={{
                      width: '25px',
                      height: '25px',
                      marginRight: '0px'
                    }}/>
                    {getBrand()}
          </div>}
          {getBrand()=='Chart' && <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
            <img src={chartImg} alt="react-logo" style={{
                      width: '30px',
                      height: '30px',
                      marginRight: '0px'
                    }}/>
                    {getBrand()}
          </div>}
          {getBrand()=='User' && <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
            <img src={userImg} alt="react-logo" style={{
                      width: '30px',
                      height: '30px',
                      marginRight: '0px'
                    }}/>
                    {getBrand()}
          </div>}
          {getBrand()=='Company' && <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
            <img src={companyImg} alt="react-logo" style={{
                      width: '30px',
                      height: '30px',
                      marginRight: '0px'
                    }}/>
                    {getBrand()}
          </div>}
          {getBrand()!='Invoice' && getBrand()!='Chart' && getBrand()!='User'  && getBrand()!='Company' && getBrand()}
          </NavbarBrand>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          
          <Nav navbar>
            
            <NavItem>
              <div className="nav-link btn-rotate" style={{
                fontSize: "25px",
                cursor: "pointer"
              }} onClick={handleSignout}>
                {logoImg && logoImg !== "" ? (
                      <img 
                        src={logoImg} 
                        alt="Logo" 
                        width={30} 
                        height={30} 
                        style={{
                          marginRight: "5px",
                          borderRadius: "30px"
                        }}
                      />
                    ) : null}
                <span style={{fontSize: "15px", marginRight: "10px"}}>{teamName}</span>
                <LogoutIcon />
                <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p>
              </div>
            </NavItem>
          </Nav>
        </Collapse>
    </Navbar>
  );
}

export default Header;
