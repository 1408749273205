// src/store.js

import { createStore, applyMiddleware, compose } from 'redux'
import { thunk as thunkMiddleware } from 'redux-thunk';

import reducers from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(history = undefined) {
  var middlewares = [thunkMiddleware]

  return createStore(
    reducers,
    composeEnhancers(
      applyMiddleware(...middlewares)
    ),
  )
}
