// src/reducers/charts-reducer.js

import {
  GET_CHARTS,
  GET_CHARTS_REQUESTED,
  GET_CHARTS_COMPLETED,
  GET_CHARTS_FAILED,
} from '../actions/charts'

const initialState = {
  isRequesting: false,
  isRequestingFailed: false,
  data: undefined,
};

export default function getChartsReducer(state = initialState, action = {}) {

  switch(action.type) {
  case GET_CHARTS_REQUESTED:
    return Object.assign({}, state, {
      isRequesting: true,
      isRequestingFailed: false,
    })

  case GET_CHARTS_FAILED:
    return Object.assign({}, state, {
      isRequesting: false,
      isRequestingFailed: true,
    })

  case GET_CHARTS_COMPLETED:
    return Object.assign({}, state, {
      isRequesting: false,
      isRequestingFailed: false,
      data: action.results,
    })

  default:
    return state
  }
}
