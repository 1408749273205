import React from 'react';
import './css/normalize.css'; 

const CheckoutFail = () => {
  const restartDemo = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    const styleLink = document.createElement("link");
    styleLink.rel = "stylesheet";
    styleLink.href = "./css/global.css";
    document.head.appendChild(styleLink);

    return () => {
      const head = document.head;
      head.removeChild(styleLink);
    };
  }, []);

  return (
    <>
      <head>
        <meta charset="utf-8" />
        <title>Stripe Checkout Sample</title>
        <meta name="description" content="A demo of Stripe Payment Intents" />
        <link rel="stylesheet" href="css/normalize.css" />
        <link rel="stylesheet" href="css/global.css" />
      </head>
      <body>
        <div className="togethere-background"></div>
        <div className="sr-root">
          <div className="sr-main">
            <header className="sr-header">
              <div className="sr-header__logo"></div>
            </header>
            <div className="sr-payment-summary completed-view">
              <h1>Your payment was canceled</h1>
              <button className='stripe' onClick={restartDemo}>Restart demo</button>
            </div>
          </div>
          <div className="sr-content">
            {/* Additional content can go here */}
          </div>
        </div>
      </body>
    </>
  );
};

export default CheckoutFail;
