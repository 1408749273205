import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

const PromptDialog = ({ open, onClose, onConfirm, github, gitlab, userId }) => {
    const [inputGithubValue, setInputGithubValue] = useState('');
    const [inputGitlabValue, setInputGitlabValue] = useState('');
    const [user, setUser] = useState(userId)
    const handleConfirm = () => {
        onConfirm(inputGithubValue, inputGitlabValue,user);
        if(inputGithubValue != "" && inputGitlabValue != "") {
            setInputGithubValue(''); // Clear input field
            setInputGitlabValue(''); // Clear input field
            onClose(); // Close dialog
        }
    };
    useEffect(() => {
        setInputGithubValue(github); // Clear input field
        setInputGitlabValue(gitlab); // Clear input field
        setUser(userId)
    }, [github, gitlab, userId])
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Please enter your new git name.</DialogTitle>
            <DialogContent>
                <div style={{ display:"flex", alignItems: "center", gap: "10px", marginTop: "5px"}}>
                    <b>Github:</b>
                    <input
                        autoFocus
                        type="text"
                        style={{ padding: '5px', margin: '0px', border: '1px solid #bdbaba'}}
                        value={inputGithubValue}
                        onChange={(e) => setInputGithubValue(e.target.value)}
                    />
                </div>
                <div style={{ display:"flex", alignItems: "center", gap: "10px", marginTop: "10px"}}>
                    <b>GitLab:</b>
                    <input
                        autoFocus
                        type="text"
                        style={{ padding: '5px', margin: '0px', border: '1px solid #bdbaba'}}
                        value={inputGitlabValue}
                        onChange={(e) => setInputGitlabValue(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PromptDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    github: PropTypes.string.isRequired,
    gitlab: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
};

export default PromptDialog;
