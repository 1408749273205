import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Header from 'components/header/header.js';
import Footer from 'components/footer/footer.js';

export default function Policy() {

  return (
    <div >
    <Header />
    <Container sx={{ padding: { xs: 2, sm: 4 }, marginTop: '100px', backgroundColor: '#f9f9f9', color: '#333' }}>
        <Typography variant="h2" sx={{ fontWeight: 'bold', marginBottom: 2,  textAlign: 'center' }}>Privacy Policy</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>Last updated: September 2024</Typography>
        <Typography variant="body2">At Reach Co.,Ltd (“We”, “us” or “our”) , we value your privacy and are committed to protecting the personal information that you share with us. This Privacy Policy outlines how we collect, use, and safeguard information when you use our FSDbird. By using our app, you consent to the practices described here in.</Typography>

        {/* Section 1 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>1. Information We Collect</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>When you use our FSDbird, we may collect the following information:</Typography>
        <List sx={{ paddingLeft: 2 }}>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Slack Profile Information: We collect user names, Slack IDs, email addresses, and profile information necessary to identify and manage users within the app." />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Usage Data: We may collect data such as Git commits, chat activity and work hours tracked by the app." />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Invoice Information: For automated invoicing, we collect data on tracked work hours, rates, logos, billing information and other relevant invoicing information." />
            </ListItem>
        </List>
        <Typography variant="body1" sx={{  marginTop: 2, }}>We do not access private messages, personal files, or any information unrelated to the app&apos;s core functionalities.</Typography>
        {/* Section 1 end*/}  

        {/* Section 2 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>2. How We Use Your Information</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>We use the information collected for the following purposes:</Typography>
        <List sx={{ paddingLeft: 2 }}>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
            <ListItemText primary="To operate and provide the core functionalities of the app, such as tracking work hours, Git commits, chat activity, and generating invoices." />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
            <ListItemText primary="To personalize the user experience, including displaying data relevant to the user or organization." />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
            <ListItemText primary="To improve and optimize our app’s performance." />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
            <ListItemText primary="To respond to support requests or troubleshoot issues." />
            </ListItem>
        </List>
        <Typography variant="body1" sx={{  marginTop: 2, }}>We do not sell or share your personal information with third parties for marketing purposes.</Typography>
        {/* Section 2 end */}

         {/* Section 3 start*/}
         <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>3. Data Security </Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>
        We prioritize the security of your data. We implement industry-standard security measures, including encryption and secure communication protocols, to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of data transmission or storage is 100% secure. While we strive to protect your information, we cannot guarantee absolute security.
        </Typography>
        
        {/* Section 3 end */}

        {/* Section 4 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>4. Data Retention </Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>
        We retain your information for as long as it is necessary to provide you with our services and fulfill the purposes described in this Privacy Policy. You can request the deletion of your data by contacting us at administrator@fsdbird.com.
        </Typography>
        
        {/* Section 4 end */}

        {/* Section 5 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>5. User Rights</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>You have the right to:</Typography>
        <List sx={{ paddingLeft: 2 }}>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Access, update, or delete the information we hold about you." />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Withdraw your consent for us to process your information." />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                    <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Request a copy of the data we collect from your use of the app." />
            </ListItem>
            
        </List>
        <Typography variant="body1" sx={{  marginTop: 2, }}>To exercise these rights, please contact us at administrator@fsdbird.com.</Typography>
        {/* Section 5 end */}

        {/* Section 6 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>6. Payments </Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>
            We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).
        </Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>
            We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
        </Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>
            The payment processors we work with are:
        </Typography>
        <Typography variant="body1" sx={{  fontWeight: 'bold' }}>
            Stripe:
        </Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>
            Their Privacy Policy can be viewed at: <a style={{ color: 'blue'}} href='https://stripe.com/us/privacy'>https://stripe.com/us/privacy</a>
        </Typography>
        {/* Section 6 end */}

        {/* Section 7 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>7. Children&apos;s Privacy</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>Our Services are not intended for use by children under the age of 13 (“Children”).</Typography>
        <Typography variant="body1" sx={{  marginTop: 2, }}>We do not knowingly collect personally identifiable information from Children under 13. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</Typography>
        {/* Section 7 end */}

        {/* Section 8 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>8. Third-Party Integrations</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>Our app interacts with Slack’s APIs and may interact with other third-party services as required to provide core functionalities. We encourage you to review Slack’s privacy policy and those of any other services that our app may integrate with for more information on their data handling practices.</Typography>
        {/* Section 8 end */}

        {/* Section 9 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>9. Changes to This policy</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you via Slack or email. We encourage you to periodically review this Privacy Policy for any updates.</Typography>
        {/* Section 9 end */}

        {/* Section 10 start*/}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>10. Contact Us</Typography>
        <Typography variant="body1" sx={{  marginBottom: 2, }}>
            If you have any questions or concerns regarding this Privacy Policy or our data handling practices, please contact us at:
        </Typography>

        <Typography variant="body1">
            Reach Co.,Ltd
        </Typography>
        <Typography variant="body1">
           Fukuoka City, Higashi Ward, Najima 4-36-12
        </Typography>
        <Typography variant="body1">
           administrator@fsdbird.com
        </Typography>
        {/* Section 10 end */}
    </Container>
    <Footer />
    </div>
  );
}
