// src/actions/tokens.js

import * as api from './api_client'

export const GET_TOKEN = "GET_TOKEN"
export const GET_TOKEN_REQUESTED = "GET_TOKEN_REQUESTED"
export const GET_TOKEN_COMPLETED = "GET_TOKEN_COMPLETED"
export const GET_TOKEN_FAILED = "GET_TOKEN_FAILED"

export function getTokenRequested() {
  return {
    type: GET_TOKEN_REQUESTED
  }
}

export function getToken(code) {
  return function(dispatch) {
    dispatch(getTokenRequested());
    return api.getApi(`/get_token?code=${code}`)
      .then((results)=> {
        dispatch(getTokenCompleted(results))
      })
      .catch((error)=> {
        Promise.all([
          dispatch(getTokenFailed(error)),
        ])
      })
  }
}

export function getTokenCompleted(results) {
  return {
    type: GET_TOKEN_COMPLETED,
    results
  }
}

export function getTokenFailed(error) {
  return {
    type: GET_TOKEN_FAILED,
    error
  }
}
