// src/layouts/InvoiceHistory/YearSelector.js
import React from 'react';
import PropTypes from 'prop-types';

const YearSelector = ({ years, selectedYear, onChange }) => {
  return (
    <select
      value={selectedYear}
      onChange={(e) => onChange(e.target.value)}
      className="year-selector"
    >
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

// Define PropTypes for the YearSelector component
YearSelector.propTypes = {
  years: PropTypes.arrayOf(PropTypes.number).isRequired, // Array of numbers
  selectedYear: PropTypes.number.isRequired, // Selected year must be a number
  onChange: PropTypes.func.isRequired // onChange handler must be a function
};

export default YearSelector;
