// src/reducers/prices-reducer.js

import {
    GET_PRICES,
    GET_PRICES_REQUESTED,
    GET_PRICES_COMPLETED,
    GET_PRICES_FAILED,
  } from '../actions/prices'
  
  const initialState = {
    isRequesting: false,
    isRequestingFailed: false,
    data: undefined,
  };
  
  export default function getPricesReducer(state = initialState, action = {}) {
  
    switch(action.type) {
    case GET_PRICES_REQUESTED:
      return Object.assign({}, state, {
        isRequesting: true,
        isRequestingFailed: false,
      })
  
    case GET_PRICES_FAILED:
      return Object.assign({}, state, {
        isRequesting: false,
        isRequestingFailed: true,
      })
  
    case GET_PRICES_COMPLETED:
      return Object.assign({}, state, {
        isRequesting: false,
        isRequestingFailed: false,
        data: action.results,
      })
  
    default:
      return state
    }
  }
  