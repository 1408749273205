import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './css/normalize.css';
import './css/global.css';
import './css/pricing.css'; // Custom CSS for styling
import config from '../../config';
import actions from '../../actions';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../components/useAuth';
import Header from "../../components/header/header.js";
import Footer from "../../components/footer/footer.js";
import { Row, Col, Card, Button } from 'antd';
import ContactUs from 'components/ContactUS';

const baseUrl = config.baseUrl;

const PricingPage = ({ PricesData, isRequesting }) => {

  const isAuthenticated = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  const dispatch = useDispatch();
  const [quarterPrice, setQuarterPrice] = useState('');
  const [monthPrice, setMonthPrice] = useState('');
  const [yearPrice, setYearPrice] = useState('');
  const [quarterPriceUnit, setQuarterPriceUnit] = useState('');
  const [monthPriceUnit, setMonthPriceUnit] = useState('');
  const [yearPriceUnit, setYearPriceUnit] = useState('');

  let formattedDate = new Date();
  useEffect(() => {
    dispatch(actions.getPrices(formattedDate));
  }, [dispatch]);

  useEffect(() => {
    if (PricesData) {
      setQuarterPrice(PricesData.quarterPrice || '');
      setMonthPrice(PricesData.monthPrice || '');
      setYearPrice(PricesData.yearPrice || '');
      setQuarterPriceUnit(PricesData.quarterPriceUnit || '');
      setMonthPriceUnit(PricesData.monthPriceUnit || '');
      setYearPriceUnit(PricesData.yearPriceUnit || '');
    }
  }, [PricesData]);

  const handleSubmit = (planType) => {
    let priceId;
    switch (planType) {
      case 'BASIC':
        priceId = monthPrice;
        break;
      case 'PROFESSIONAL':
        priceId = quarterPrice;
        break;
      case 'ENTERPRISE':
        priceId = yearPrice;
        break;
      default:
        console.error('Invalid plan type selected');
        return;
    }

    const team_id = localStorage.getItem('team_id');

    const formData = new URLSearchParams();
    formData.append('priceId', priceId);
    formData.append('team_id', team_id);

    fetch(`${baseUrl}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.url) {
          window.location.href = data.url;
        } else {
          console.error('Checkout session creation failed');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
    <div className='common-back'>
      <Header />
      <div className="pricing-container">
        <h1 className="pricing-title">Choose Your Plan</h1>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 50 }}>
          <Col xs={24} sm={12} md={8} style={{paddingLeft: 35, paddingRight: 35}}>
            <Card
              className="pricing-card"
              title="MONTH"
              hoverable
              bordered={false}
              headStyle={{ fontSize: '1.8rem', 
                textAlign: 'center', 
                paddingTop: '15px', 
                paddingBottom: '15px', 
                color: '#d4ae14',
                borderBottom: '3px solid #f0f0f0'
              }}
            >
              <div className="pricing-amount">${monthPriceUnit}/ Month</div>
              <p>Perfect for companies or teams in Slack.</p>
              <ul className="pricing-features">
                <li>15 Days Free Trial</li>
                <li >Access to All Features</li>
                <li style={{ marginBottom: '79px' }}>Unlimited Users</li>
              </ul>
              <Button
                type="primary"
                block
                onClick={() => handleSubmit('BASIC')}
              >
                Get Started
              </Button>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} style={{paddingLeft: 35, paddingRight: 35}}>
            <Card
              className="pricing-card"
              title="QUATRTER"
              hoverable
              bordered={false}
              headStyle={{ fontSize: '1.8rem', 
                textAlign: 'center', 
                paddingTop: '15px', 
                paddingBottom: '15px', 
                color: '#d4ae14',
                borderBottom: '3px solid #f0f0f0'
              }}
            >
              <div className="pricing-amount">${quarterPriceUnit}/ 3 Months</div>
              <p>Saving cost using FSDbird.</p>
              <ul className="pricing-features">
                <li><s>$17 * 3 = $51</s></li>
                <li>Save 7% over monthly</li>
                <li>Access to All Features</li>
                <li style={{ marginBottom: '45px' }}>Unlimited Users</li>
              </ul>
              <Button
                type="primary"
                block
                onClick={() => handleSubmit('PROFESSIONAL')}
              >
                Get Started
              </Button>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} style={{paddingLeft: 35, paddingRight: 35}}>
            <Card
              className="pricing-card"
              title="YEAR"
              hoverable
              bordered={false}
              headStyle={{ fontSize: '1.8rem', 
                textAlign: 'center', 
                paddingTop: '15px', 
                paddingBottom: '15px', 
                color: '#d4ae14',
                borderBottom: '3px solid #f0f0f0'
              }}
            >
              <div className="pricing-amount">${yearPriceUnit}/ Year</div>
              <p>Saving cost dramatically!</p>
              <ul className="pricing-features">
                <li><s>$17 * 12 = $204</s></li>
                <li>Save 15% over monthly</li>
                <li>Access to All Features</li>
                <li style={{ marginBottom: '45px' }}>Unlimited Users</li>
              </ul>
              <Button
                type="primary"
                block
                onClick={() => handleSubmit('ENTERPRISE')}
              >
                Get Started
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
      </div>
      <div style={{marginTop: '50px'}}>
        <ContactUs />
      </div>
      
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  PricesData: state.prices.data,
  isRequesting: state.prices.isRequesting,
  isRequestingFailed: state.prices.isRequestingFailed
});

PricingPage.propTypes = {
  PricesData: PropTypes.object,
  isRequesting: PropTypes.bool,
  isRequestingFailed: PropTypes.bool
};

export default connect(mapStateToProps)(PricingPage);
