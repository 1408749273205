import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useAuth } from '../../components/useAuth';
import actions from '../../actions';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from 'examples/Footer';
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import AdminLayout from 'layouts/Admin'
import ChartUsersTable from './chatUsersTable';
dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay)(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const Chartlayout = ({ workReportData }) => {
  const today = new Date();
  const formattedTodayDate = today.toLocaleDateString();
  const [chartTitle, setChartTitle] = useState('Work report of today: '+ formattedTodayDate)
  const [hoveredDay, setHoveredDay] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [period, setPeriod] = useState('day');
  const [value, setValue] = useState(dayjs('2022-04-17'));
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();

  const isInSameWeek = useCallback((dayA, dayB) => {
    if (dayB == null) {
      return false;
    }
    return dayA.isSame(dayB, period);
  }, [period]);

  const fetchData = useCallback((date) => {
    const formattedDate = date.format('YYYY/MM/DD');
    const team_id = localStorage.getItem('team_id');
    dispatch(actions.getCharts(team_id, formattedDate, period));
  }, [dispatch, period]);

  useEffect(() => {
    fetchData(selectedDate);
  }, [fetchData, selectedDate, period]);

  const chartTitleFunction = (period, date) => {
    if(period == "week"){
      const startOfWeek = dayjs(date).startOf('week'); // sunday
      const endOfWeek = dayjs(date).endOf('week');  // saturday

      setChartTitle("Work report of the week: " + startOfWeek.format('MM/DD/YYYY') + " ~ " + endOfWeek.format('MM/DD/YYYY'));

    } 
    else if(period == "month"){
      const month = dayjs(date).format('MM'); // Month in 'MM' format
      const year = dayjs(date).format('YYYY'); // Year in 'YYYY' format

      setChartTitle("Work report of this month: " + `${month}/${year}`);
    }
    else
      setChartTitle('Work report of day: ' + dayjs(date).format('MM/DD/YYYY'));
  }

  const handleChange = (event, newPeriod) => {
      if (newPeriod !== null) {
          setPeriod(newPeriod);
          chartTitleFunction(newPeriod, selectedDate)
         
        }
    
  };

  const sortByKey = useCallback((array, key) => {
    if (array) {
      return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
    return [];
  }, []);

  const [tableData, setTableData] = useState([])
  const reportBarData = useMemo(() => {
    if (!workReportData) return { labels: [], datasets: [] };

    const usersData = sortByKey(workReportData.users_data, 'id');
    const chartData = sortByKey(workReportData.chart_data, 'user_id');
    const newChartData = usersData.map(user => {
      const existingData = chartData.find(data => data.user_id === user.id);
      
      if (existingData) {
        return {
          name: user.name,
          ...existingData
        };
      } else {
        return { 
          user_id: user.id, 
          name: user.name,
          work_time: 0, 
          github_commit: "0", 
          chat_history: "0" 
        };
      }
    });

    newChartData.sort((a, b) => a.user_id - b.user_id);
    setTableData(newChartData)
    const usersById = usersData.reduce((acc, user) => {
      acc[user.id] = user.name;
      return acc;
    }, {});

    const labels = Object.values(usersById);
    const workTimeData = newChartData.map(item => item.work_time);
    const gitCommitData = newChartData.map(item => item.github_commit);
    const chatCoundData = newChartData.map(item => item.chat_history);

    return {
      labels,
      datasets: [
        { label: "working time", color: "orange", data: workTimeData },
        { label: "git push", color: "primary", data: gitCommitData },
        { label: "chatting number", color: "info", data: chatCoundData },
      ],
    };
  }, [sortByKey, workReportData]);

  const containerWidth = useMemo(() => {
    if (!workReportData) return 1000;

    const chartBarWidth = 30 + Object.keys(workReportData.users_data).length * 100;
    return chartBarWidth > 1000 ? chartBarWidth : 1000;
  }, [workReportData]);

  const Day = (props) => {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
      <CustomPickersDay
        {...other}
        day={day}
        disableMargin
        selected={false}
        isSelected={isInSameWeek(day, selectedDay)}
        isHovered={isInSameWeek(day, hoveredDay)}
      />
    );
  };

  Day.propTypes = {
    day: PropTypes.object.isRequired,
    selectedDay: PropTypes.object,
    hoveredDay: PropTypes.object,
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <AdminLayout>
      <MDBox py={3} pl={10}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
          <DatePicker
              label="Select the date"
              value={selectedDate}
              onChange={(newValue) => {
                setValue(newValue)
                setSelectedDate(newValue);
                chartTitleFunction(period, newValue)
              }}
              showDaysOutsideCurrentMonth
              // displayWeekNumber
              slots={{ day: Day }}
              slotProps={{
                day: (ownerState) => ({
                  selectedDay: value,
                  hoveredDay,
                  onPointerEnter: () => setHoveredDay(ownerState.day),
                  onPointerLeave: () => setHoveredDay(null),
                }),
              }}
            />
            
            <ToggleButtonGroup
              color="primary"
              value={period}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              size="small"
            >
              <ToggleButton value="month">Month</ToggleButton>
              <ToggleButton value="week">Week</ToggleButton>
              <ToggleButton value="day">Day</ToggleButton>
            </ToggleButtonGroup>
          </DemoContainer>
         
        </LocalizationProvider>
        <div style={{ display: "flex", gap:"0px"}}>
          <div style={{width:"70%"}}>
            <MDBox mt={4.5} >
              <Grid container spacing={3} style={{ overflow: 'auto' , width: '100%'}} >
                <Grid item xs={12} md={12} lg={12} style={{ width: `${containerWidth}px` }}>
                  <MDBox mb={3}>
                    <VerticalBarChart
                      title={chartTitle}
                      description=""
                      chart={reportBarData}
                      height="400px"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>

          </div>
          <div style={{width:"30%", marginLeft: '-20px'}}>
          <MDBox mt={4.5} style={{ overflow: 'auto' , marginLeft: "10px"}}>
            <Grid container spacing={3} style={{ width: '100%' }}>
              <Grid item xs={12} md={12} lg={12}>
                <ChartUsersTable data = {tableData}/>
              </Grid>
              
            </Grid>
            
          </MDBox>
          </div>
          
        </div>
      </MDBox>
      <Footer />
    </AdminLayout>
  );
};

const mapStateToProps = (state) => ({
  workReportData: state.charts.data,
});

Chartlayout.propTypes = {
  workReportData: PropTypes.object,
};

export default connect(mapStateToProps)(Chartlayout);
