// src/reducers/index.js

import { combineReducers } from 'redux'

import tokensReducer from './tokens-reducer'
import chartsReducer from './charts-reducer'
import pricesReducer from './prices-reducer'

export default combineReducers({  
  charts: chartsReducer,
  tokens: tokensReducer,
  prices: pricesReducer,
})
