/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import * as api from 'actions/api_auth_client'
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import users_icon from "assets/images/users.png";
import React, { useState, useEffect } from 'react';
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import PropTypes from 'prop-types';
import { Height } from '@mui/icons-material';

export default function ChartUsersTable({data}) {
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  // Use the useEffect hook to fetch data when the component mounts
  useEffect(() => {
    const updatedRowData = data.map((temp, index)=> ({
            
        no: index + 1,
        name: <b style={{color: '#344767'}}>{temp.name}</b>,
        work_time: temp.work_time,
        github_commit: temp.github_commit,
        chat_history: temp.chat_history
    }));    
    setData(updatedRowData)
  }, [data]); 

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!rows || rows.length === 0) {
    return <p ></p>;
  }

  const columns = [
    // { Header: "No", accessor: "no", align: "left" },
    { Header: "User", accessor: "name", align: "left" },
    { Header: "WorkTime", accessor: "work_time", align: "center" },
    { Header: "Git Amount", accessor: "github_commit", align: "center" },
    { Header: "Chatting Amount", accessor: "chat_history", align: "center" },
  ]

  return (
    <div style={{
      height: "474px",
      overflow: "auto"
    }}>
    <DataTable
        table={{ columns, rows }}
        isSorted={true}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
        style={{
          Height: "474px"
        }}
    />
    </div>
  );
}

ChartUsersTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        work_time: PropTypes.number.isRequired,
        github_commit: PropTypes.number.isRequired,
        chat_history: PropTypes.number.isRequired
    })).isRequired,

};
