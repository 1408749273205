import React, {useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText 
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import * as api from '../../actions/api_auth_client'
import AdminLayout from 'layouts/Admin'
import './company.css'
import config from '../../config';

function Company() {
  const notificationAlert = React.useRef();
  const notify = (place, msg, color) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {msg}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    country: '',
    postal_code: '',
    logoFile: null,
    logo_name: '',
  });

  const [logoImg, setLogoImg] = useState("")
  useEffect(() => {
    let team_id = localStorage.getItem('team_id')
    
    const apiUrl = `/get-company/${team_id}`;
    api.getApi(apiUrl)
        .then((response) => {
          setFormData((prevData) => ({
            ...prevData,
            ...response['company']
          }));
          localStorage.setItem("logoImg", `data:image/png;base64,${response['company']['logoFileBase64']}`)
          setLogoImg( `data:image/png;base64,${response['company']['logoFileBase64']}`)
        })
        .catch(error => {
          console.error(error)
        });
  }, [])
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        logoFile: file,
        logo_name: file.name, // Automatically set logo_name to the file's name
      }));

      const reader = new FileReader();
        reader.onloadend = () => {
          setLogoImg(reader.result);
        };
        reader.readAsDataURL(file);

    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = 'Name is required';
    if (!formData.address) tempErrors.address = 'Address is required';
    if (!formData.city) tempErrors.city = 'City is required';
    if (!formData.country) tempErrors.country = 'Country is required';
    if (!formData.postal_code) tempErrors.postal_code = 'Postal Code is required';
    // if (!formData.logoFile) tempErrors.logoFile = 'Logo file is required';
    // if (!formData.logo_name) tempErrors.logo_name = 'Logo name is required';

    return Object.keys(tempErrors).length === 0;
  };

  

  const handleSubmit = (e) => {
    if (validate()) {
      const data = new FormData();
      data.append('name', formData.name);
      data.append('address', formData.address);
      data.append('city', formData.city);
      data.append('country', formData.country);
      data.append('postal_code', formData.postal_code);
      data.append('logoFile', formData.logoFile);
      data.append('logo_name', formData.logo_name);
      
      let ws_id = localStorage.getItem('ws_id')
      data.append('workspace_id', ws_id)

      let team_id = localStorage.getItem('team_id')
      data.append('team_id', team_id)

      fetch(`${config.baseUrl}/save-company`, {
        method: 'POST',
        body: data,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result.ok) {
            // If the status is not OK (e.g., 500), throw an error to trigger the catch block
            notify("tr", "Failed to update the company", 3)
            return
          }

          notify("tr", "Updated the company successfully", 5)
          localStorage.setItem("logoImg", logoImg)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }else{
    notify("tr", "Please fill out all fields", 3)
  }
}
  return (
    <AdminLayout>
      
      <div className="content company-content" style={{ marginTop: "20px", paddingLeft: "80px"}}>
        <NotificationAlert ref={notificationAlert} />
        <Row>
          <Col md="8">
            <Card className="card-user">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Company Name</label>
                        <Input
                          // defaultValue=""
                          placeholder=""
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          value={formData.address}
                          placeholder=""
                          type="text"
                          name="address"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          value={formData.city}
                          name="city"
                          placeholder=""
                          type="text"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          value={formData.country}
                          placeholder=""
                          type="text"
                          name="country"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input 
                          value={formData.postal_code}
                          placeholder="" type="text"  name="postal_code" onChange={handleChange}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Upload Company Logo</label>
                        <Input
                            type="file"
                            name="logoFile"
                            id="fileInput"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        <FormText color="muted">
                            Please upload only image files (jpg, png, gif, etc.).
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    {logoImg && logoImg !== "" ? (
                      <img 
                        src={logoImg} 
                        alt="Logo" 
                        width={100} 
                        height={100} 
                      />
                    ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto text-right">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="button"
                        style={{
                            fontSize: "12px",
                            backgroundColor: "#51bcda",
                            borderColor: "#51bcda"
                        }}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </AdminLayout>
  );
}

export default Company;
