import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const useAuth = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Replace this with the actual logic to retrieve your auth token

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      return decoded.exp < currentTime;
    } catch (err) {
      return true;
    }
  };
  
  if (!token || isTokenExpired(token)) {
    localStorage.removeItem('token');
    localStorage.removeItem('team_name');
    localStorage.removeItem('team_id');
    localStorage.removeItem('ws_id');

    navigate('/');
    return false;
  }
  return true;
};
