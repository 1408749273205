import React, { useEffect, useState } from "react";
import config from '../../config'
import slackWindow1 from '../../assets/img/slack-window-1.jpg'
import slackWindow2 from '../../assets/img/slack-window-2.jpg'
import './banner.css'; 

const HeaderBanner = () => {
  const [imgNum, setImgNum] = useState(0)
  const [zIndex1, setZIndex1] = useState(1)
  const [zIndex2, setZIndex2] = useState(0)
  const [currentZIndex, setCurrentZIndex] = useState(1)
  const [classNames1, setClassNames1] = useState('zoomInFast')
  const [classNames2, setClassNames2] = useState('zoomInFast')

  const run_animation = () => {
    setTimeout(() => {
      if(imgNum == 0) setClassNames2('zoomInFast zoomOutFast')
      else setClassNames1('zoomInFast zoomOutFast')

      setTimeout(() => {
        setCurrentZIndex(1 - currentZIndex)

        if(imgNum == 0) {
          setZIndex2(1)
          setZIndex1(0)
          setClassNames2('zoomInFast')
        }else{
          setZIndex2(0)
          setZIndex1(1)
          setClassNames1('zoomInFast')
        }
        
        setImgNum(1-imgNum)
      }, 800)
    }, 1500)
  }
  
  const svgStyle = {
    height: '20px',
    width: '20px',
    marginRight: '12px'
  };

  const handleAddSlack = () => {
    const slackClientId = config.slackClientId;
    const scopes = encodeURIComponent('app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,commands,files:read,files:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:history,mpim:read,team:read,users:read,users:read.email,users.profile:read');
    const slackUrl = `https://slack.com/oauth/v2/authorize?client_id=${slackClientId}&scope=${scopes}&user_scope=`;

    window.location.href = slackUrl;
  };

  useEffect(() => {
    run_animation()
  }, [currentZIndex])

  return (
    <div className="static-slider-head" style={{
      background: "linear-gradient(229.31deg, #2832A6 0%, #7324A1 100%)",
      padding: "150px 0px 80px 0px"
    }}>

      <div
        style={{
          maxWidth: "1320px",
          margin: "auto",
          gridColumnGap: "32px",
          display: "grid",
          gridTemplateColumns: 'repeat(12, 1fr)'
        }}
        className="container-grid background-images"
      >
        <div className="feats-text" style={{
            color: 'white',
            gridColumn: '1/5'
          }
          }>
          <h2 className="feats--title" >
           Optimize  Productivity!
          </h2>

          <div className="feats--content text-justify">
            Elevate your team’s efficiency with our all-in-one Slack app. Monitor work hours, track chat and Git activity, and manage everything seamlessly within your Slack workspace. Automate invoicing with precision using real-time data.<br/>Simplify your processes, boost productivity, and stay ahead of the curve. Experience the future of team management today—get started now!
          </div>
          
          <div className="addSlackButton">
            <button
              className="slack-sign"
              style={{
                height: '56px',
                display: 'flex',
                alignItems: 'center',
                width: 'auto',
                border: '0px',
                backgroundColor: 'white',
                color: '#57585A',
                padding: '16px',
                fontFamily: 'Lato',
                fontSize: '16px',
                borderRadius: '100px',
                margin: 'auto',
                marginTop: '15px',
                border: 'none',
                boxShadow: 'none',
              }}
              onClick={handleAddSlack}
            >
              <svg xmlns="http://www.w3.org/2000/svg" style={svgStyle} viewBox="0 0 122.8 122.8">
                <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
              </svg>
              Add to Slack
            </button>
          </div>
        </div>
        <div style={{
            color: 'white',
            gridColumn: '6/13'
          }}
          className="feats-image"
        >
          <figure className={classNames1} style={{zIndex: zIndex1}}>
            <img 
              decoding="async" 
              width="1280" 
              height="896" alt="" 
              className="attachment-full size-full lazyloaded" sizes="(max-width: 1280px) 100vw, 1280px" src={`${slackWindow1}`} />  
          </figure>
          <figure 
            className={classNames2}
            style={{zIndex: zIndex2}}
          >
            <img decoding="async" width="1280" height="896" alt="" 
              className="attachment-full size-full lazyloaded" sizes="(max-width: 1280px) 100vw, 1280px" src={`${slackWindow2}`} />  
            </figure>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
