// src/actions/charts.js

import * as api from './api_auth_client'

export const GET_CHARTS = "GET_CHARTS"
export const GET_CHARTS_REQUESTED = "GET_CHARTS_REQUESTED"
export const GET_CHARTS_COMPLETED = "GET_CHARTS_COMPLETED"
export const GET_CHARTS_FAILED = "GET_CHARTS_FAILED"

export function getChartsRequested() {
  return {
    type: GET_CHARTS_REQUESTED
  }
}

export function getCharts(team_id, selectedDate, period) {
  return function(dispatch) {
    dispatch(getChartsRequested());
    return api.getApi(`/get_charts?team_id=${team_id}&date=${selectedDate}&period=${period}`)
      .then((results)=> {
        dispatch(getChartsCompleted(results))
      })
      .catch((error)=> {
        Promise.all([
          dispatch(getChartsFailed(error)),
        ])
      })
  }
}

export function getChartsCompleted(results) {
  return {
    type: GET_CHARTS_COMPLETED,
    results
  }
}

export function getChartsFailed(error) {
  return {
    type: GET_CHARTS_FAILED,
    error
  }
}
