import React, { useState } from 'react';
import Footer from 'components/footer/footer.js';
import Header from 'components/header/header.js';
import ContactUs from 'components/ContactUS';
import { Container } from '@mui/material';

export default function Support() {

    return (
      <div>
        <Header />
        <Container sx={{ padding: { xs: 2, sm: 4 }, marginTop: '100px', backgroundColor: '#f9f9f9', color: '#333' }}>
          <ContactUs />
        </Container>
        <Footer />
      </div>
    );
  }
  